import { render, staticRenderFns } from "./WaitingApprovalList.vue?vue&type=template&id=5507357c&scoped=true&"
import script from "./WaitingApprovalList.vue?vue&type=script&lang=js&"
export * from "./WaitingApprovalList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5507357c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/dev/workspace/Maxwork/maxwork-msf-0-DEVELOPMENT/maxwork-msf-01-DEV-web-cms-build-n-deploy/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5507357c')) {
      api.createRecord('5507357c', component.options)
    } else {
      api.reload('5507357c', component.options)
    }
    module.hot.accept("./WaitingApprovalList.vue?vue&type=template&id=5507357c&scoped=true&", function () {
      api.rerender('5507357c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/factoryEquipments/factoryEquipmentList/approvalList/WaitingApprovalList.vue"
export default component.exports